import { message } from "antd";
import axios from "axios";
const API_URL = process.env.REACT_APP_BACKEND_HOST + "/api/";

class AuthService {
  async login(login, password) {
    const response = await axios.post(
      API_URL + "login",
      {
        login,
        password,
      },
      { withCredentials: true }
    );
    return response.data;
  }
  logout() {
    return axios.get(API_URL + "logout", { withCredentials: true });
  }

  register(user) {
    return axios.post(
      API_URL + "register",
      {
        ...user,
      },
      { withCredentials: true }
    );
  }

  async edit(user) {
    return axios
      .post(
        API_URL + "user/edit",
        {
          ...user,
        },
        { withCredentials: true }
      )
      .then(() => {
        message.success("Данные изменены");
      })
      .catch((error) => {
        message.error(error);
      });
  }

  async changePassword(newPass, user) {
    return axios
      .post(
        API_URL + "user/change-password",
        {
          user: user,
          password: newPass,
        },
        { withCredentials: true }
      )
      .then(() => {
        message.success("Данные изменены");
      })
      .catch((error) => {
        message.error(error);
      });
  }

  async removeUser(id) {
    return axios
      .delete(API_URL + "user/remove/" + id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("hash"),
        },
      })
      .then((resp) => {
        if (resp.data.message !== "error") {
          message.success("Помечено на удаление");
        } else {
          message.error(resp.data.data);
        }
      })
      .catch((error) => {
        message.error(error);
      });
  }

  async getCurrentUser() {
    return await axios
      .get(API_URL + "user", {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  async getUserList() {
    return await axios
      .get(API_URL + "user/list", { withCredentials: true })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        message.error(error);
      });
  }
}
export default new AuthService();
