import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";

import App from "./App";

const routing = (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// console.log = console.warn = console.error = () => {};

const root = ReactDOM.createRoot(document.getElementById("garias"));
root.render(routing);
