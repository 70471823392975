import React, { lazy, Suspense } from "react";

import { Route, Routes } from "react-router-dom";
import { Form, Input, Layout, Menu, Modal, Spin, Typography } from "antd";
import { Row, Col, Button, Dropdown } from "antd";
import { UserOutlined } from "@ant-design/icons";

import {
  UnorderedListOutlined,
  HomeOutlined,
  SettingOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";

import "antd/dist/antd.min.css";
import "./App.css";

import AuthService from "./services/auth.service";

const Home = lazy(() => import("./elements/Home"));
const Tasks = lazy(() => import("./elements/Tasks"));
const Lib = lazy(() => import("./elements/Lib"));
const Migration = lazy(() => import("./elements/Migration"));
const Storages = lazy(() => import("./elements/Storages"));
const UsersAndGroups = lazy(() => import("./elements/UsersAndGroups"));
const MediaFormats = lazy(() => import("./elements/MediaFormats"));
const Login = lazy(() => import("./elements/Login"));

const { Header, Sider, Footer } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

let items = [];
const itemsUser = [
  getItem("Главная", "/", <HomeOutlined />),
  getItem("Задачи", "/tasks", <UnorderedListOutlined />),
  getItem("Медиа", "/list", <PlayCircleOutlined />),
];

const itemsAdmin = [
  getItem("Настройки", "sub2", <SettingOutlined />, [
    getItem("Форматы медиа", "/media-format"),
    getItem("Политики миграций", "/migrations"),
    getItem("Хранилища", "/storage-setting"),
    getItem("Пользователи и группы", "/users"),
  ]),
];

export const UserContext = React.createContext();

const userItems = [
  getItem("Сменить пароль", "changePass"),
  { type: "divider" },
  getItem("Выйти", "logout"),
];

class App extends React.Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);
    this.state = {
      newPass: "",
      retryPass: "",
      loading: false,
      currentUser: undefined,
    };
  }

  async getUser() {
    this.setState({ loading: true });

    try {
      const user = await AuthService.getCurrentUser();

      if (user) {
        if (user.role === "admin") {
          items = itemsUser.concat(itemsAdmin);
        } else {
          items = itemsUser;
        }
        this.setState({
          currentUser: user,
          loading: false,
          // showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
          // showAdminBoard: user.roles.includes("ROLE_ADMIN"),
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ loading: false });
    }
  }

  componentDidMount() {
    this.getUser();
  }

  logOut() {
    AuthService.logout();
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const { currentUser, loading } = this.state;
    if (currentUser) {
      return (
        <Layout>
          {currentUser ? (
            <Sider
              trigger={null}
              collapsible
              collapsed={this.state.collapsed}
              width={240}
            >
              <div className="logo" />
              <Menu
                theme="dark"
                defaultSelectedKeys={["1"]}
                defaultOpenKeys={["sub1", "sub2"]}
                mode="inline"
                items={items}
                onClick={(item) => {
                  window.location.href = item.key;
                }}
              ></Menu>
            </Sider>
          ) : (
            <></>
          )}
          <Layout className="site-layout">
            <Header
              className="site-layout-background"
              style={{
                padding: 0,
              }}
            >
              <Row>
                <Col span={6}>
                  {currentUser ? (
                    <>
                      {React.createElement(
                        this.state.collapsed
                          ? MenuUnfoldOutlined
                          : MenuFoldOutlined,
                        {
                          className: "trigger",
                          onClick: this.toggle,
                        }
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col span={14}></Col>
                <Col
                  span={4}
                  style={{
                    textAlign: "right",
                    paddingRight: 10,
                  }}
                >
                  <>
                    {!currentUser ? (
                      <Button type="text" href="/login">
                        <UserOutlined />
                        Войти
                      </Button>
                    ) : (
                      <Dropdown
                        menu={{
                          items: userItems,
                          onClick: (item) => {
                            switch (item.key) {
                              case "logout":
                                this.logOut();
                                break;
                              case "changePass":
                                Modal.info({
                                  centered: true,
                                  title: "Смена пароля",
                                  content: (
                                    <>
                                      <Form
                                        layout="vertical"
                                        style={{ paddingTop: "40px" }}
                                      >
                                        <Form.Item label="Новый пароль">
                                          <Input
                                            type="password"
                                            onChange={(event) => {
                                              if (
                                                event.target.value ===
                                                this.state.retryPass
                                              ) {
                                                document.getElementById(
                                                  "passTrue"
                                                ).style.display = "block";
                                                document.getElementById(
                                                  "passFalse"
                                                ).style.display = "none";
                                                document
                                                  .getElementById("okPass")
                                                  .removeAttribute("disabled");
                                              } else {
                                                document.getElementById(
                                                  "passTrue"
                                                ).style.display = "none";
                                                document.getElementById(
                                                  "passFalse"
                                                ).style.display = "block";
                                                document
                                                  .getElementById("okPass")
                                                  .setAttribute("disabled", "");
                                              }
                                              this.setState({
                                                newPass: event.target.value,
                                              });
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item label="Повторите пароль">
                                          <Input
                                            type="password"
                                            onChange={(event) => {
                                              if (
                                                event.target.value ===
                                                this.state.newPass
                                              ) {
                                                document.getElementById(
                                                  "passTrue"
                                                ).style.display = "block";
                                                document
                                                  .getElementById("okPass")
                                                  .removeAttribute("disabled");
                                                document.getElementById(
                                                  "passFalse"
                                                ).style.display = "none";
                                              } else {
                                                document.getElementById(
                                                  "passTrue"
                                                ).style.display = "none";
                                                document
                                                  .getElementById("okPass")
                                                  .setAttribute("disabled", "");
                                                document.getElementById(
                                                  "passFalse"
                                                ).style.display = "block";
                                              }
                                              this.setState({
                                                retryPass: event.target.value,
                                              });
                                            }}
                                          />
                                        </Form.Item>
                                        <Typography.Text
                                          id="passTrue"
                                          type="success"
                                        >
                                          Пароли совпадают
                                        </Typography.Text>
                                        <Typography.Text
                                          id="passFalse"
                                          type="danger"
                                        >
                                          Пароли не совпадают
                                        </Typography.Text>
                                      </Form>
                                    </>
                                  ),
                                  okButtonProps: {
                                    id: "okPass",
                                    // disabled: true,
                                  },
                                  onOk: () => {
                                    if (
                                      document.getElementById("passTrue").style
                                        .display === "block"
                                    ) {
                                      AuthService.changePassword(
                                        this.state.newPass,
                                        this.state.currentUser.id
                                      ).then(() => {
                                        this.logOut();
                                      });
                                    }
                                  },
                                  closable: true,
                                });
                                setTimeout(() => {
                                  document
                                    .getElementById("okPass")
                                    .setAttribute("disabled", "disabled");
                                }, 100);

                                break;
                            }
                          },
                        }}
                      >
                        <Button type="link">
                          <UserOutlined />
                          {currentUser.firstName}
                        </Button>
                      </Dropdown>
                    )}
                  </>
                </Col>
              </Row>
            </Header>
            <UserContext.Provider value={this.state.currentUser}>
              <Suspense
                fallback={
                  <Spin size="large" style={{ margin: "90% 0" }}></Spin>
                }
              >
                <Routes>
                  <Route exact path="/" element={<Home />} />
                  <Route exact path="/list" element={<Lib />} />
                  <Route exact path="/tasks" element={<Tasks />} />
                  {this.state.currentUser.role === "admin" ? (
                    <Route exact path="/migrations" element={<Migration />} />
                  ) : (
                    <></>
                  )}
                  {this.state.currentUser.role === "admin" ? (
                    <Route
                      exact
                      path="/storage-setting"
                      element={<Storages />}
                    />
                  ) : (
                    <></>
                  )}
                  {this.state.currentUser.role === "admin" ? (
                    <Route
                      exact
                      path="/media-format"
                      element={<MediaFormats />}
                    />
                  ) : (
                    <></>
                  )}
                  {this.state.currentUser.role === "admin" ? (
                    <Route exact path="/users" element={<UsersAndGroups />} />
                  ) : (
                    <></>
                  )}
                  <Route exact path="/login" element={<Login />} />
                </Routes>
              </Suspense>
            </UserContext.Provider>
            <Footer
              style={{
                textAlign: "center",
              }}
            >
              Гариас СПМ ©2024 — Сделано в России
            </Footer>
          </Layout>
        </Layout>
      );
    } else if (!loading) {
      return (
        <Layout style={{ height: "100%" }}>
          <Login state={this.state} />
        </Layout>
      );
    } else if (loading) {
      <Layout style={{ height: "100%" }}>
        <Spin tip="Загрузка..."></Spin>;
      </Layout>;
    }
  }
}

export default App;
